import * as React from "react"
import type PunchedStep from "../configuration/PunchedStep"
import { useIntl } from "react-intl"
import {
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons"
import TimeWidget from "../../shared/TimeWidget/TimeWidget"
import PlannerTime from "../../shared/plannerDateTime/PlannerTime"
import { Button } from "antd"
import makeCounterString from "./makeCounterString"
import useStepCallbacks from "./hooks/useStepCallbacks"

export default function MobilePunchRunningStep({
  steps,
  step,
  stepNumber,
  update,
  deleteDisabled,
}: {
  step: PunchedStep
  steps: PunchedStep[]
  stepNumber: number
  deleteDisabled: boolean
  update: () => Promise<void>
}): JSX.Element {
  const intl = useIntl()
  const {
    edit,
    updateTimeForModify,
    illegalTime,
    editBtnCallback,
    deleteBtnCallback,
    save,
  } = useStepCallbacks({ step, steps, stepNumber, update })
  const [simpleTime, setSimpleTime] = React.useState(
    formatSimpleTime(step.time)
  )
  React.useEffect(() => {
    if (formatSimpleTime(step.time) !== simpleTime) {
      setSimpleTime(formatSimpleTime(step.time))
    }
  }, [step, simpleTime])
  const realTime = React.useMemo(
    () =>
      step?.utc?.timestamp
        ? new Date(step.utc.timestamp).toLocaleTimeString(intl.locale, {
            hour: "2-digit",
            hour12: false,
            hourCycle: "h24",
            minute: "2-digit",
          })
        : null,
    [intl.locale, step.utc.timestamp]
  )
  const timezonesDiffer = React.useMemo(
    () => realTime !== simpleTime,
    [realTime, simpleTime]
  )
  const title = React.useMemo(
    () =>
      timezonesDiffer
        ? intl.formatMessage({ id: "running-diff-tz" })
        : undefined,
    [intl, timezonesDiffer]
  )
  const breakIsStarting = React.useMemo(
    () => step.type === "break-start" && stepNumber === 0,
    [step.type, stepNumber]
  )
  const breakEnd = React.useMemo(
    () =>
      !breakIsStarting &&
      step.type === "break-start" &&
      steps[stepNumber - 1]?.type === "break-end",
    [breakIsStarting, step.type, steps, stepNumber]
  )
  // const [now, setNow] = React.useState(new Date().getTime())
  // React.useEffect(() => {
  //   const i = window.setInterval(() => setNow(new Date().getTime()), 250)
  //   return () => window.clearInterval(i)
  // }, [])
  const pauseBrackets = React.useMemo(() => {
    let addl = ""
    if (breakEnd) {
      let end = steps[stepNumber - 1]?.utc.timestamp
      let start = step.utc.timestamp
      start = Math.floor(start / 60000) * 60
      end = Math.floor(end / 60000) * 60
      if (start !== undefined) {
        addl = ` (${makeCounterString({
          seconds: end - start,
        })})`
      }
      // } else if (breakIsStarting) {
      //   const start = step.utc.timestamp
      //   const end = now
      //   if (start !== undefined) {
      //     addl = ` (${makeCounterString({ seconds: (end - start) / 1000 })})`
      //   }
    }
    return addl
  }, [breakEnd, step.utc.timestamp, stepNumber, steps])
  const simpleTimeForWidget = React.useMemo(
    () => PlannerTime.fromJSON(simpleTime),
    [simpleTime]
  )
  const cls = React.useMemo(
    () => `punched-step${edit ? " punched-step-edit" : ""}`,
    [edit]
  )
  return (
    <div className={cls} title={title}>
      <div className="punched-step-main">
        <CaretRightOutlined className="punched-current-marker"></CaretRightOutlined>{" "}
        <div
          className={illegalTime ? "illegal-time-punched" : ""}
          style={{
            display: edit ? "inline-block" : "none",
          }}
        >
          <TimeWidget
            getPopupContainer={() => document.querySelector("body")!}
            onChange={updateTimeForModify}
            value={simpleTimeForWidget}
          ></TimeWidget>
        </div>
        {!edit ? simpleTime : null}: {intl.formatMessage({ id: step.type })}
        {pauseBrackets}
        {!edit && stepNumber === 0 ? (
          <Button
            size="small"
            type="ghost"
            icon={<EditOutlined></EditOutlined>}
            onClick={editBtnCallback}
          ></Button>
        ) : null}
        <div
          className="punched-step-delete-btn-container"
          style={{
            flexGrow: 1,
            textAlign: "end",
          }}
        >
          <Button
            style={{
              cursor: edit && !deleteDisabled ? "pointer" : "default",
              color: edit ? "rgb(206, 67, 94)" : "white",
              display: edit ? "inline-block" : "none",
              marginRight: 4,
              // marginTop: -4,
            }}
            disabled={deleteDisabled}
            size="small"
            type="ghost"
            icon={
              deleteDisabled ? (
                <DeleteOutlined></DeleteOutlined>
              ) : (
                <DeleteFilled></DeleteFilled>
              )
            }
            onClick={deleteBtnCallback}
          ></Button>
        </div>
        {edit ? (
          <div className="punched-step-buttons">
            <Button
              style={{
                cursor: edit ? "pointer" : "default",
                display: edit ? "inline-block" : "none",
              }}
              disabled={illegalTime}
              type="primary"
              icon={<CheckOutlined></CheckOutlined>}
              onClick={save}
            >
              {intl.formatMessage({ id: "save" })}
            </Button>
            <Button
              type="default"
              icon={
                edit ? (
                  <CloseOutlined></CloseOutlined>
                ) : (
                  <EditOutlined></EditOutlined>
                )
              }
              onClick={editBtnCallback}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          </div>
        ) : null}
      </div>
      {timezonesDiffer ? (
        <div className="punched-timezones-differ">
          <span className="punched-step-different-tz">
            {intl.formatMessage({ id: "timezonesDiffer" }, { value: realTime })}
          </span>
        </div>
      ) : null}
    </div>
  )
}

function formatSimpleTime({
  hour,
  minute,
}: {
  hour: number
  minute: number
}): string {
  const h = `${hour}`.padStart(2, "0")
  const m = `${minute}`.padStart(2, "0")
  return `${h}:${m}`
}
