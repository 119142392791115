import * as React from "react"
import type { EntryV3 } from "../../../commonInterfaces/PlannerV3"
import { makeMakeMobileEntry } from "../../calendar/EditableLoggedEntry"
import type PlannerData from "../../configuration/PlannerData"
import { useIntl } from "react-intl"

interface UseMobilePoolCallbacks {
  returnToPool: () => void
}

export default function useMobilePoolReturnAllCallback({
  employeeId,
  departmentId,
  date,
  plannerData,
  triggerReload,
}: {
  plannerData?: PlannerData
  departmentId: string
  date: string
  employeeId: string
  triggerReload: () => void
}): UseMobilePoolCallbacks {
  const intl = useIntl()
  const returnToPool = React.useCallback(() => {
    const employees = // Usually just one list (assigned), may have multiple when assignment changes
      plannerData
        ?.getPlannerV3()
        ?.employeeCalendarsByOrganisationalUnit[departmentId]?.filter(
          e =>
            e.id === employeeId &&
            e.relationshipToOrganisationalUnit.find(
              r => r.name === "assigned" && r.date === date
            )
        ) ?? []
    let entriesV3: EntryV3[] = []
    let supersedingEntriesV3: EntryV3[] = []
    // 1 find superseding entries
    for (const employee of employees) {
      const previousSupersedingEntries = [...supersedingEntriesV3]
      supersedingEntriesV3 = [
        ...supersedingEntriesV3,
        ...employee.entries.filter(
          e =>
            e.isAllDay &&
            e.startDate <= date &&
            e.endDate &&
            e.endDate >= date &&
            !previousSupersedingEntries.find(se => se.dayId === e.dayId)
        ),
      ]
    }
    const isWeirdDate = supersedingEntriesV3.length > 1
    const isSuperseded = supersedingEntriesV3.length > 0
    if (isWeirdDate) {
      console.warn(
        "Date is superseded by multiple unavailabilities!",
        JSON.stringify(supersedingEntriesV3)
      )
    }
    // 2 decide between actual and superseded entries
    for (const employee of employees) {
      const entryList = isSuperseded
        ? employee.supersededEntries
        : employee.entries
      entriesV3 = [...entriesV3, ...entryList.filter(e => e.startDate === date)]
    }
    const makeMobileEntry = makeMakeMobileEntry(plannerData?.getPlannerV3())
    const entries = [...entriesV3.map(makeMobileEntry)]
    void plannerData
      ?.returnAllToPool(date, entries, supersedingEntriesV3[0])
      .then(success => {
        if (success) {
          triggerReload()
          // void plannerData
          //  .load()
          //  .then(
          //    triggerReload
          //  ) /* TODO: Delay this via service worker with read model */
        } else {
          alert(intl.formatMessage({ id: "pool-mobile-return-failed" }))
        }
      })
  }, [plannerData, departmentId, date, employeeId, triggerReload, intl])
  return {
    returnToPool,
  }
}
