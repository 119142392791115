import * as React from "react"
import type { EntryV3 } from "../../../commonInterfaces/PlannerV3"
import { makeMakeMobileEntry } from "../../calendar/EditableLoggedEntry"
import type PoolEntry from "../../../commonInterfaces/PoolEntry"
import type PlannerData from "../../configuration/PlannerData"
import { useIntl } from "react-intl"
import { timeToString } from "@mina-works/planner-utils"
import { EXTRABUFFERFORSAVE } from "./EXTRABUFFERFORSAVE"

interface UseMobilePoolCallbacks {
  save: () => void
}

export default function useMobilePoolCallbacks({
  selectedEntry,
  employeeId,
  departmentId,
  plannerData,
  triggerReload,
  block,
  blocked,
}: {
  selectedEntry: PoolEntry
  plannerData?: PlannerData
  departmentId: string
  employeeId: string
  triggerReload: () => void
  block: (b: boolean) => void
  blocked: boolean
}): UseMobilePoolCallbacks {
  const intl = useIntl()
  const save = React.useCallback(() => {
    if (blocked) return
    const convertedEntry = convertPoolEntryToEntryV3(selectedEntry)
    if (convertedEntry === null) {
      console.error("Cannot convert pool entry for saving:", selectedEntry)
      return
    }
    const employees =
      plannerData
        ?.getPlannerV3()
        ?.employeeCalendarsByOrganisationalUnit[departmentId]?.filter(
          e => e.id === employeeId
        ) ?? []
    let entriesV3: EntryV3[] = []
    for (const employee of employees) {
      entriesV3 = [
        ...entriesV3,
        ...employee.entries.filter(e => e.startDate === selectedEntry.date),
      ]
    }
    const makeMobileEntry = makeMakeMobileEntry(plannerData?.getPlannerV3())
    const entries = [
      ...entriesV3.map(makeMobileEntry),
      makeMobileEntry(convertedEntry),
    ]
    block(true)
    void plannerData
      ?.mergeFromPool(selectedEntry.date, entries)
      .then(success => {
        if (success) {
          triggerReload()
          // void plannerData
          //  .load()
          //  .then(
          //     triggerReload
          //  ) /* TODO: Delay this via service worker with read model */
        } else {
          alert(
            intl.formatMessage(
              { id: "pool-mobile-save-failed" },
              {
                start: selectedEntry.startTime
                  ? timeToString(selectedEntry.startTime)
                  : "-",
                end: selectedEntry.endTime
                  ? timeToString(selectedEntry.endTime)
                  : "-",
              }
            )
          )
        }
      })
      .finally(() => setTimeout(() => block(false), EXTRABUFFERFORSAVE))
  }, [
    block,
    blocked,
    departmentId,
    employeeId,
    plannerData,
    selectedEntry,
    triggerReload,
    intl,
  ])
  return {
    save,
  }
}

function convertPoolEntryToEntryV3(poolEntry: PoolEntry): EntryV3 | null {
  if (poolEntry.identifier) {
    return {
      poolReference: {
        streamName: poolEntry.lastMessage?.streamName,
        globalPosition: poolEntry.lastMessage?.globalPosition,
        departmentId: poolEntry.departmentId,
        date: poolEntry.date,
        identifier: poolEntry.identifier,
      },
      startDate: poolEntry.date,
      startTime: poolEntry.startTime,
      endTime: poolEntry.endTime,
      breaks: poolEntry.breaks,
      organisationalUnitIdForThisEntry: poolEntry.departmentId,
      entryClassification:
        poolEntry.calendarEntryType?.id !== undefined &&
        poolEntry.calendarEntryType.name !== undefined &&
        poolEntry.calendarEntryType.abbreviation !== undefined
          ? {
              ...poolEntry.calendarEntryType,
              id: poolEntry.calendarEntryType.id,
              name: poolEntry.calendarEntryType.name,
              abbreviation: poolEntry.calendarEntryType.abbreviation,
              color: poolEntry.calendarEntryType.color ?? {
                r: 120,
                g: 120,
                b: 120,
              },
              isActivity: true,
            }
          : undefined,
    }
  } else {
    return null
  }
}
